import React from "react";
import HelpCards from "../../pages/Help/HelpCards";
import "../../pages/Help/index.css";

export default function HelpDocs() {
  return (
    <div className="help_container">
      <HelpCards />
    </div>
  );
}
